<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-subheader>Plantillas de datos</v-subheader>
                    <v-data-table
                        :headers="headers"
                        :items="DataTemplates"
                        sort-by="name_table"
                        class="elevation-1">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Listado de plantillas de datos</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-dialog
                                    v-model="dialogDataTemplate"
                                    fullscreen
                                    hide-overlay
                                    transition="dialog-bottom-transition"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            dark
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="newTemplate">
                                            Nueva plantilla
                                        </v-btn>

                                        
                                    </template>
                                    <v-card>
                                        <v-toolbar
                                        dark
                                        color="primary"
                                        >
                                        <v-btn
                                            icon
                                            dark
                                            @click="dialogDataTemplate = false"
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                        <v-toolbar-title>Plantilla de datos</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-toolbar-items>
                                            <v-btn
                                                dark
                                                text
                                                @click="sendTable()">
                                                Guardar
                                            </v-btn>
                                        </v-toolbar-items>
                                        </v-toolbar>
                                        <v-stepper
                                            v-model="stepperDataTemplate"
                                            vertical
                                        >
                                            <v-stepper-step
                                                :complete="stepperDataTemplate > 1"
                                                step="1"
                                                editable>
                                                Información plantilla
                                            </v-stepper-step>

                                            <v-stepper-content step="1">

                                            <v-card
                                                color="cyan lighten-5"
                                                class="mb-12"
                                                height="100px"
                                            >
                                                <v-form>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="4">
                                                                <v-text-field
                                                                    label="Nombre plantilla"
                                                                    required
                                                                    v-model="dataTemplate.name_table"
                                                                    id="nombre-planilla"
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="4">
                                                                <v-autocomplete
                                                                    :items="Clients"
                                                                    label="Categorias"
                                                                    v-model="clientData"
                                                                    id="cliente-id"
                                                                    return-object
                                                                    @input="loadProducts"
                                                                ></v-autocomplete>
                                                            </v-col>
                                                            <v-col cols="4">
                                                                <v-autocomplete
                                                                    :items="Secretaries"
                                                                    label="Secretarias"
                                                                    v-model="secretaryData"
                                                                    id="product-id"
                                                                    return-object
                                                                    @input="loadProductsSecretary"
                                                                ></v-autocomplete>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-form>
                                            </v-card>

                                            <v-card
                                                color="cyan lighten-5"
                                                class="mb-12"
                                                height="100px"
                                            >
                                                <v-form>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="6">
                                                                <v-autocomplete
                                                                    :items="Products"
                                                                    label="Productos"
                                                                    v-model="dataTemplate.product_id"
                                                                    id="product-id"
                                                                ></v-autocomplete>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <v-autocomplete
                                                                    :items="architectures"
                                                                    item-text="name_architecture"
                                                                    item-value="id"
                                                                    label="Segmentos"
                                                                    name="id"
                                                                    v-model="architecture_id"
                                                                    @change="isArchitecture()"
                                                                ></v-autocomplete>
                                                            </v-col>
                                                        </v-row>
                                                       
                                                    </v-container>
                                                </v-form>
                                            </v-card>

                                            </v-stepper-content>

                                         
                                            <v-stepper-step
                                            :complete="stepperDataTemplate > 3"
                                            step="2"
                                            editable>
                                            Carga de información
                                            </v-stepper-step>

                                            <v-stepper-content step="2">
                                            <v-card
                                                color="grey lighten-1"
                                                class="mb-12"
                                                height="60px"
                                            >
                                            <div class="text-center">
                                                <v-btn
                                                    style="height: 45px;"
                                                    class="ma-2"
                                                    
                                                    color="secondary"
                                                    @click="$refs.fileInput.click()"
                                                > Seleccionar archivo importador de tablas </v-btn>
                                                <input type="file" :accept="SheetJSFT" ref="fileInput" @change="inputChange" />
                                            

                                                
                                            </div>
                                            </v-card>
                                            </v-stepper-content>

                                            <v-stepper-step 
                                                step="3"
                                                editable
                                            >
                                                Gráficos
                                            </v-stepper-step>
                                            <v-stepper-content step="3">
                                            <v-card
                                                color="grey lighten-1"
                                                class="mb-12"
                                                height="45px"
                                            >
                                            
                                            <v-select
                                                                    :items="Types"
                                                                    label="Tipo de graficas"
                                                                    v-model="dataTemplate.graph_type_id"
                                                                ></v-select>
                                            </v-card>
                                            </v-stepper-content>
                                            <v-stepper-step step="4" editable>
                                                Visualización de información
                                            </v-stepper-step>
                                            <v-stepper-content step="4">
                                            <v-card
                                                color="grey lighten-1"
                                                class="mb-12"
                                            >
                                             <div v-if="dataTemplate.graph_type_id == 1">
                                                <h3>Grafica lineal</h3>
                                                <line-chart :data="chartData" :messages="{empty: 'No data'}"></line-chart>
                                            </div>
                                            <div v-if="dataTemplate.graph_type_id == 2">
                                                <h3>Grafica en columnas</h3>
                                                <column-chart :data="chartData"  :messages="{empty: 'No data'}"></column-chart>
                                            </div>
                                            <div v-if="dataTemplate.graph_type_id == 3">
                                                <h3>Grafica de Pie</h3>
                                                <pie-chart :data="chartData" :messages="{empty: 'No data'}"></pie-chart>
                                            </div>
                                            <div  v-if="dataTemplate.graph_type_id == 4">
                                                <h3>Grafica tipo barras horizontales</h3>
                                                <bar-chart :data="chartData" :messages="{empty: 'No data'}"></bar-chart>
                                            </div>
                                            <div  v-if="dataTemplate.graph_type_id == 5">
                                                <h3>Grafica area bajo la linea</h3>
                                                <area-chart :data="chartData" :messages="{empty: 'No data'}"></area-chart>
                                            </div>
                                            <div  v-if="dataTemplate.graph_type_id == 9">
                                                <h3>Grafica Dispersion</h3>
                                                <scatter-chart :data="chartData" :messages="{empty: 'No data'}"></scatter-chart>
                                            </div>

                                            <div  v-if="dataTemplate.graph_type_id == 6">
                                                <h3>Grafica tipo barras horizontales</h3>
                                                <bar-chart :data="barsData" :messages="{empty: 'No data'}"></bar-chart>
                                            </div>
                                            <div  v-if="dataTemplate.graph_type_id == 7">
                                                <h3>Grafica area bajo la linea</h3>
                                                <area-chart :data="barsData" :messages="{empty: 'No data'}"></area-chart>
                                            </div>
                                            <div  v-if="dataTemplate.graph_type_id == 8">
                                                <h3>Grafica Dispersion</h3>
                                                <scatter-chart :data="barsData" :messages="{empty: 'No data'}"></scatter-chart>
                                            </div>
                                            <div v-if="dataTemplate.graph_type_id == 10" class="speedometer__container">                                               
                                                    <div class="speedometer__container--gauge">
                                                        <div class="speedometer__container--gauge__title"><p>{{dataTemplate.name_table}}</p> </div>
                                                        <VueSvgGauge
                                                            :start-angle="-115"
                                                            :end-angle="115"
                                                            :value="value"
                                                            :separator-step="1"
                                                            :min="0"
                                                            :max="quantity"
                                                            :gauge-color="[{ offset: 0, color: 'red'},{ offset: valueMid, color: 'yellow'},{ offset: 100, color: 'green'}]"
                                                            :scale-interval="0.8"
                                                        />
                                                        <div  class="speedometer__container--gauge__details">
                                                            <div class="speedometer__container--details_high">
                                                            <p>{{highValueText}}</p><div class="square green"></div>
                                                            </div>
                                                            <!-- <div class="speedometer__container--details_high">
                                                            <p>Medio</p><div class="square yellow"></div>
                                                            </div> -->
                                                            <div class="speedometer__container--details_high">
                                                            <p>{{lowValueText}}</p><div class="square red"></div>
                                                            </div>
                                                            <div class="speedometer__container--details_high">
                                                            <p>{{labelResult}}</p><p>{{result}}</p>
                                                            </div>
                                                        </div>

                                                        <div class="speedometer__container--description">
                                                            <div class="speedometer__container--description__principal">
                                                                <p>{{grayText}}</p>
                                                            </div>
                                                            <div class="speedometer__container--description__principal--price">
                                                                <p>{{greenText}}</p>
                                                            </div>
                                                            <div  class="speedometer__container--description__invest">
                                                                <p class="speedometer__container--description__invest--title">{{labelOne}}: </p><p>{{valueOne}}</p>
                                                            </div>
                                                            <div class="speedometer__container--description__future--invest">
                                                                <p class="speedometer__container--description__invest--title">{{labelTwo}}: </p><p>{{valueTwo}}</p>
                                                            </div>
                                                        </div>
                                                    
                                                </div>
                                                    <!--  or  -->
                                                    <!-- <vue-svg-gauge
                                                        :start-angle="-110"
                                                        :end-angle="110"
                                                        :value="5"
                                                        :separator-step="0"
                                                        :min="0"
                                                        :max="10"
                                                        gauge-color="#8CDFAD"
                                                        :scale-interval="0.1"
                                                    />
                                                    <VueSvgGauge
                                                        :start-angle="-110"
                                                        :end-angle="110"
                                                        :value="random"
                                                        :separator-step="20"
                                                        :scale-interval="10"
                                                        :inner-radius="80"
                                                    /> -->
                                            </div>

                                            <div  v-if="dataTemplate.graph_type_id == 11">
                                                <h3>Grafica tipo barras horizontales</h3>
                                                <bar-chart :data="horizontalBarsData" :messages="{empty: 'No data'}"></bar-chart>
                                            </div>

                                            <div  v-if="dataTemplate.graph_type_id == 12">
                                                <h3>Tabla</h3>

                                                <table>
                  
                                                    <tr>
                                                        <th>INDICADOR EN EL PDM 2020-2023</th>
                                                        <th>UNIDAD DE MEDIDA</th>
                                                        <th>META 2020</th>
                                                        <th>EJECUTADO 2020</th>
                                                        <th>META 2021</th>
                                                        <th>EJECUTADO 2021</th>
                                                        <th>META 2022</th>
                                                        <th>AVANCE 2022</th>
                                                        <th>META 2023</th>
                                                    </tr>
                                                    <tr v-for="item in tableData" :key="item">
                                                        <td>{{ item[3]}}</td>
                                                        <td>{{ item[4]}}</td>
                                                        <td>{{ item[5]}}</td>
                                                        <td>{{ item[6]}}</td>
                                                        <td>{{ item[7]}}</td>
                                                        <td>{{ item[8]}}</td>
                                                        <td>{{ item[9]}}</td>
                                                        <td>{{ item[10]}}</td>
                                                        <td>{{ item[11]}}</td>
                                                    </tr>
                                                </table>
                                                
                                            </div>

                                            <div  v-if="dataTemplate.graph_type_id == 13">
                                                <h3>Grafica tipo barras horizontales categorías</h3>
                                                <bar-chart :data="horizontalBarsDataCategory" :messages="{empty: 'No data'}"></bar-chart>
                                            </div>

                                            </v-card>
                                            </v-stepper-content>
                                        </v-stepper>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editTemplate">
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                class="mr-2"
                                @click="getData">
                                mdi-card-bulleted-settings
                            </v-icon>
                            <v-icon
                                small
                                class="mr-2"
                                @click="deleteData(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <template v-slot:no-data>
                            No se encontraron registros
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import XLSX, { read } from "xlsx"
import JSZip, { files } from "jszip"
import { Bar } from 'vue-chartjs'
import {misMixins} from '../../../vue/mixinsAlert';
const _SheetJSFT = [ "zip" ].map(x => "." + x).join(",")
export default {
    data() {
        return {
            value:'',
            result:'',
            valueLow:'',
            valueMid:'',
            valueHigh:'',
            quantity:'10',
            finalprueba:[],
            dimensions:[],
            architectures:[],
            chartData: {},
            barsData:{},
            dataExcel: [],
            dataSend: [],
            loader: null,
            loading: false,
            btnDisable: true,
            SheetJSFT: _SheetJSFT,
            zip: null,
            xlsx: null,
            tables:[],
            dialogDataTemplate: false,
            dataTemplate: {
                id: 0,
                name_table: '',
                client_id: '',
                data_table: [],
                graph_types_id:'',
                fields: [],
                product_id: '',
                sub_category_product_id:'',
                graph_kpi:''
            },
            headers: [
                {
                    text: 'Nombre plantilla',
                    align: 'start',
                    value: 'name_table',
                },
                { text: 'Cliente', value: 'client' },
                { text: 'Acciones', value: 'actions', sortable: false },
            ],
            DataTemplates: [],
            stepperDataTemplate: 1,
            architecture_id:'',
            dataType: {
                id: 0,
                name_field: '',
                type_field: ''
            },
            dataTypes: [
                {
                    text: 'Entero',
                    value: 'int'
                },
                {
                    text: 'Real',
                    value: 'float'
                },
                {
                    text: 'Cadena',
                    value: 'string'
                },
                {
                    text: 'Texto',
                    value: 'text'
                },
                {
                    text: 'Fecha',
                    value: 'date'
                }
            ],
            Fields: [
                {
                    name_field: 'Nombre',
                    type_field: 'string',
                    orden: 1
                },
                {
                    name_field: 'Edad',
                    type_field: 'int',
                    orden: 2
                },
                {
                    name_field: 'Ciudad',
                    type_field: 'string',
                    orden: 3
                },
            ],
            Clients: [
        
            ],
            Types:[],
            highValueText:'',
            lowValueText:'',
            labelResult:'',
            grayText:'',
            greenText:'',
            labelOne:'',
            valueOne:'',
            labelTwo:'',
            valueTwo:'',
            totalValue:'',
            horizontalBarsData:[],
            tableData:[],
            horizontalBarsDataCategory:[],
            Products:[],
            subcategoryProducts:[],
            Secretaries: [],
            clientData: [],
            secretaryData: []
            

        }
    },



    mixins:[misMixins],

    methods: {


    isArchitecture(){
 
      this.dataTemplate.client_id = ''
      console.log(this.architecture_id)
    },

    loadProducts(){
       this.Products = []
       this.dataTemplate.client_id = this.clientData.value
       this.getProducts(this.clientData.id_dimension)
       this.architecture_id = ''

    },

    loadProductsSecretary(){
        this.Products = []
        this.dataTemplate.sub_category_product_id = this.secretaryData.value
        this.getProducts(this.secretaryData.value)
     },

    isCategory(id){
        this.architecture_id = ''
        console.log(this.dataTemplate.client_id)
    },

        getPercentByKpi(){
      this.value = (this.result/this.totalValue)*10
    },
        sendTable(){
            this.LoadingOpen();
            const architecture_tag_id = this.dataTemplate.client_id
            const product_id = this.dataTemplate.product_id
            const sub_category_product_id = this.dataTemplate.sub_category_product_id
            const architecture_id = this.architecture_id
            const graph_type_id = this.dataTemplate.graph_type_id
            const name_table = this.dataTemplate.name_table
            const data =JSON.stringify(this.chartData)
            const graph_kpi =JSON.stringify(this.dataTemplate.graph_kpi)
            
            const obj = {
                architecture_tag_id:architecture_tag_id,
                graph_type_id:graph_type_id,
                name_table:name_table,
                data:data
            }

            this.$http.post('tables',{
                data:{
                    attributes:{
                        architecture_tag_id:architecture_tag_id,
                        architecture_id: architecture_id,
                        graph_type_id:graph_type_id,
                        name_table:name_table,
                        data:data,
                        product_id: product_id,
                        sub_category_product_id: sub_category_product_id,
                        graph_kpi:graph_kpi
                    }
                }
            //})
            },{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                .then(response => {
                    this.LoadingClose();

                        this.SucessAlert(
                        'Creado',
                        'datos importados con éxito'
                        )
                }).catch(error => {
                        this.LoadingClose();
                        this.ErrorAlert('Error al importar datos')
                    })
            //console.log('El Objeto en arreglo ',obj)



           this.dialogDataTemplate = false
           this.$router.push({ name: 'DataTemplate' });
        },
        async inputChange(evt){
             console.log('holiiii desde aqui')
      const files = evt.target.files
      //console.log(files[0].name.substr(-4,4))
      if( files[0].name.substr(-4,4) == '.zip'){
        if(files && files[0]){
            JSZip.loadAsync(files[0])
            .then(
                zip => {
                this.zip = zip

                zip.forEach(async (relativePath, zipEntry) => {
                    if(zipEntry.name.split(".")[1] && zipEntry.name.split(".")[1] === "xlsx"){
                    const file = await zip.file(zipEntry.name).async("arraybuffer")                  
                    const wb = XLSX.read(file, {type: 'buffer'})
                    const wsname = wb.SheetNames[0]
                    const ws = wb.Sheets[wsname]
                    const data = XLSX.utils.sheet_to_json(ws, {header:1})
                    this.dataExcel = data
                    console.log('this.dataExcel', this.dataExcel)
                    if(this.dataExcel!= null){
                       
                        this.convertJson(this.dataExcel)
                    }
                    }
                });
                },
                e =>   {
                alert("Error reading " + ": " + e.message)
                }
            )
        }
      }
      
      if( files[0].name.substr(-4,4) == 'xlsx'){                   
          this.handleFileLoad(evt)
        }
    },
    handleFileLoad(e) {
        if (e.target.files.length > 0) {
            try {
                    const file = e.target.files.async("arraybuffer")                  
                    const wb = XLSX.read(file, {type: 'buffer'})
                    const wsname = wb.SheetNames[0]
                    const ws = wb.Sheets[wsname]
                    const data = XLSX.utils.sheet_to_json(ws)
                    this.dataExcel = data
            } catch (exception) {
            }

            
        } else {
            toast("No files found", { type: "error" });
        }
    },
    convertJson(x){
        const info = []
        const twoInfo = []

        for( var i = 0 ; i < (x.length-1) ; i++){
  
            if( x[i+1].length > 0){
                info.push(x[i+1])
            }

        }


            
            this.valueLow = info[0][1]
            this.valueMid = 50
            this.valueHigh = info[0][3]
            this.highValueText = info[0][2]
            this.lowValueText = info[0][0]
            this.labelResult = info[0][5]
            this.result = info[0][6]
            this.grayText = info[0][7],
            this.greenText = info[0][8],
            this.labelOne = info[0][9],
            this.valueOne = info[0][10],
            this.labelTwo = info[0][11],
            this.valueTwo = info[0][12]
            this.totalValue = info[0][4]
            this.getPercentByKpi()
        const y = x
        for( var i = 0 ; i < (x.length-1) ; i++){
          var r = [y[i+1][0],y[i+1][2]]
          twoInfo.push(r)
        }
        this.chartData=info
        this.barsData=[{name:x[0][1],data:info},{name:x[0][2],data:twoInfo}]
        const newJson = JSON.stringify(x)

        this.horizontalBarsData=[ [x[0][5],info[0][5]], [x[0][6],info[0][6]], [x[0][7],info[0][7]], [x[0][8],info[0][8]] ]

        this.horizontalBarsDataCategory=[ 
            [x[0][4],info[0][4]], 
            [x[0][5],info[0][5]], 
            [x[0][6],info[0][6]],
            [x[0][7],info[0][7]],
            // [x[0][8],info[0][8]],
            // [x[0][9],info[0][9]],
            // [x[0][10],info[0][10]]   
            ]

            var res = [x[0],x[1]]

            this.dataTemplate.graph_kpi = res

            this.tableData = info
   

        console.log('chartData', this.chartData)

        
        console.log('chartData ---> GRAPH', JSON.stringify(this.chartData))

        console.log('horizontalBarsData', this.horizontalBarsData)
        console.log('tableData', this.tableData)
        console.log('horizontalBarsDataCategory', this.horizontalBarsDataCategory)

    },
    
    quitarAcentos(cadena){
      const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'}
      return cadena.split('').map( letra => acentos[letra] || letra).join('').toString().toLowerCase()
    },
    mounted() {
    this.renderChart(data, options)
  },
        newTemplate(){
        },
        editTemplate(){},
        getData(){},

        deleteData(data){
            console.log('delete', data)
            this.LoadingOpen()

              this.$http.post('tables/delete/', {
            id: data.id
          })
            .then(response => {
       

                this.SucessAlert(
                        'Eliminado',
                        'gráfico eliminado con éxito'
                        )

               window.location.reload()


            }).catch(error => {
                this.ToastError(error.data.status +' '+error.data.error)
                console.log('Error al eliminar grafico', error)
            })

        },

        addDataType(){

            (this.dataTemplate.fields).push({
                id: 0,
                name_field: this.dataType.name_field,
                type_field: this.dataType.type_field,
                orden: 1
            });

            this.dataType = {
                id: 0,
                name_field: '',
                type_field: ''
            };
        },
        getDataType(type){
            var typeData = '';
            switch (type) {
                case 'string':
                    typeData = 'Cadena';
                    break;
                case 'int':
                    typeData = 'Entero';
                    break;
            }
            return typeData;
        },



 
        async getSegments(){
            console.log('las dimensiones',this.dimensions)
           
            for(var j = 0; j< this.dimensions.length; j++){
                var id_dimension = this.dimensions[j].id

               await this.$http.get('architectures/simple/'+this.dimensions[j].id)
                 //this.$http.get('architecture_tags/townhall/'+this.getClientSelect().id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                .then(response => {

                    for(var i = 0; i < response.data.length; i++){

                          this.architectures.push(response.data[i])

                        for(var k = 0; k < response.data[i].architecture_tag.length; k++){
                            // console.log(response.data[i].architecture_tag[k])
                            const value = response.data[i].architecture_tag[k].id
                            const text = response.data[i].architecture_tag[k].tag 
                            const obj={
                                value: value,
                                text: text,
                                id_dimension: id_dimension
                            }
                            this.Clients.push(obj)
                            this.getCategories(value)
                            
                        }
                    }

                    // this.getTables()
               

                   
                }).catch((error) => {
                    this.ToastError(error.data.status +' '+error.data.error)
                    
                });
            }

            return this.architectures
           
        },

        async getProducts(id){
           
            // this.$http.get('products')
            //      //this.$http.get('architecture_tags/townhall/'+this.getClientSelect().id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
            //     .then(response => {

            //         console.log('response products', response)
            //         for(var i = 0; i < response.data.length; i++){
            //                 const value = response.data[i].id
            //                 const text = response.data[i].name 
            //                 const obj={
            //                     value: value,
            //                     text: text
            //                 }
            //                 this.Products.push(obj)

            //             }
                    
            //             console.log('this.products', this.Products)
            //     }).catch((error) => {
            //         this.ToastError(error.data.status +' '+error.data.error)
                    
            //     });

            //     console.log('this.arch')

            this.$http.get('dimensions/allinone/' + id)
                 //this.$http.get('architecture_tags/townhall/'+this.getClientSelect().id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                .then(response => {
                    // console.log('ressss', response.data.Dimension[0].segments)
                    for(var i = 0; i < response.data.Dimension[0].segments.length; i++){

                        for(var k = 0; k < response.data.Dimension[0].segments[i].tags.categories.length; k++){

                            for(var j = 0; j < response.data.Dimension[0].segments[i].tags.categories[k].products.length; j++){

                                console.log('producto encontrado', response.data.Dimension[0].segments[i].tags.categories[k].products[j])
                                const value = response.data.Dimension[0].segments[i].tags.categories[k].products[j].id
                                const text = response.data.Dimension[0].segments[i].tags.categories[k].products[j].name
                                const obj={
                                    value: value,
                                    text: text
                                }
                                this.Products.push(obj)
                               
                            }

                        }
                    }
                    
                }).catch((error) => {
                    this.ToastError(error.data.status +' '+error.data.error)
                    
                });

        },

        async getSecretaries(){
           
            this.$http.get('dimensions')
                .then(response => {
                    var response = response.body;
    
                    for(var i = 0; i < this.dimensions.length; i++){

                            if(this.dimensions[i].subcategory == 1){

                                const value = this.dimensions[i].id
                                const text = this.dimensions[i].name_dimension 
                                const obj={
                                    value: value,
                                    text: text
                                }
                                this.Secretaries.push(obj)

                            }

                        }
                        console.log('Secretaries', this.Secretaries)

                }).catch(error => {
                    this.ToastError(error.data.status +' '+error.data.error)
                    console.log('Error al consultar las dimensiones', error)
                })

    

       },

        async infoTable(){

            var data = await this.getSegments();

            
            setTimeout(()=>{
                this.getTables(data)
            },2000);



        },
        

        async getCategories(id){
            // console.log(' method this.getCategories')
                    try{
                        const segment = await this.$http.get('architecture_tags/'+id)
                        this.finalprueba.push(segment.data)
                        // console.log('final',this.finalprueba)

                        if (segment.data.table.length > 0){
                            for(var i = 0; i < segment.data.table.length ; i++){
                                const obj = {
                                    name_table:segment.data.table[i].name_table,
                                    client:this.getClientSelect().name,
                                    id:segment.data.table[i].id
                                }
                                this.DataTemplates.push(obj)

                               
                            }
                        }


                        //  console.log(' this.DataTemplates.push(obj)',  this.DataTemplates)
                        
                   
                    }catch(error){
                        console.log(error)
                    }
              
            
        },

       getTables(architectures){

        //    console.log(' method this.gettables')
        //     console.log('this.architectures', this.architectures)
            console.log('this.architectures.length', architectures.length)
            for(var i = 1; i <= architectures.length; i++){

                console.log('architectures[i].id', architectures[i].id)

                this.$http.get('tables/architecture/'+architectures[i].id)
                .then(response => {

                    if( response.body.length > 0){

                        const obj = {
                                    name_table:response.body[0].name_table,
                                    client:this.getClientSelect().name,
                                    id:response.body[0].id
                                }
                                this.DataTemplates.push(obj)
                    }
                    
                }).catch(error => {
                    this.ToastError(error.data.status +' '+error.data.error)
                    console.log('Error al consultar las tablas', error)
                })

            }



            console.log('this.DataTemplates', this.DataTemplates)

           
        },

        getDimension(){
            console.log('getDimension')
            this.$http.get('dimensions/townhall/'+this.getClientSelect().id)
            .then(response => {
                this.dimensions = response.body;
                // console.log('dimensiones en get dimension',this.dimensions)
                // this.getSegments()
                this.infoTable()

            }).catch(error => {
                this.ToastError(error.data.status +' '+error.data.error)
                console.log('Error al consultar las dimensiones', error)
            })
            
        },
        async getTypes(){
            this.$http.get('graph_types')
                .then(response => {
                    const types = response.body
                    for(var i = 0; i < types.length ; i++){
                        const text = types[i].type_graph
                        const value = types[i].id
                        const obj = {
                            text:text,
                            value:value
                        }
                        this.Types.push(obj)
                    }
        })},
        
        
    },
    beforeMount(){
        this.getDimension()
        this.getTypes()
        this.getSecretaries()

    }
}
</script>
<style scoped>

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td{
  
  text-align: left;
  padding: 8px;
  font-size: 16px;
  height: 100px;
  text-align: center;
  border-right: 1px solid #ccc;
}

th{
  background-color: #383838;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  height: 60px;
  text-align: center;
}

tr:nth-child(even) {
  background-color: #F4F4F4;
  
}

.speedometer__container{
  width: 820px;
  height: 240px;
  margin: 0 auto;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid rgb(2, 1, 1);
  
}
.speedometer__container--title{
  width: 100%;
}
.speedometer__container--title h2{
  text-align: left;
}
.speedometer__container--gauge{
  width: 100%;
  height: 240px;
  display: grid;
  grid-template-columns: 2fr 2fr 4fr;
  grid-template-rows: 1fr 3fr;
}
.speedometer__container--details_high{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.square{
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid black;
}
.green{
  background-color: rgba(0, 128, 21,0.6);
}
.red{
  background-color: rgba(255, 0, 0,0.6);
}
.yellow{
  background-color: rgba(255, 255, 0,0.6);
}
.gauge{
    margin-left: 30px;
    width: 150px ;
    height: 150px;
    grid-row: 2;
}
.speedometer__container--gauge__details{
    font-size: 10px;
    margin: 0 20px 0 40px;
    grid-row: 2;
    width: auto;
    height: auto;
}
.speedometer__container--description{
    margin: 30px 30px 30px 30px ;
    display:flex;
    flex-wrap: wrap;
    width: 356px;
    grid-column: 3/4;
    grid-row: 1/3;
    
}


.speedometer__container--description__principal{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 356px;
    background: #D8D8D8;
}
.speedometer__container--description__principal p{
    text-align: center;
    padding: 0;
    margin:0
}
.speedometer__container--description__principal--price{
    margin-top: 3px;
    height: 38px;
    width: 356px;
    display:flex;
    justify-content: center;
    align-items: center;
    background: #69C061;
    border-radius: 0px 0px 20px 20px;
}
.speedometer__container--description__principal--price p{
    text-align: center;
    padding: 0;
    margin:0
}
.speedometer__container--description__invest{
    margin: 0 auto;
    margin-top: 24px;
    height: 34px;
    width: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F4F4;
}
.speedometer__container--description__invest p{
   padding: 0;
    margin: 0;
}
.speedometer__container--description__future--invest{
    margin: 0 auto;
    margin-top: 6px;
    height: 34px;
    width: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F4F4;
}
.speedometer__container--description__future--invest p{
    padding: 0;
    margin: 0;
}
.speedometer__container--gauge__title{
    margin: 20px 0 0 30px ;
}
.speedometer__container--gauge__title p{
    padding: 0;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
}
.speedometer__container--description__invest--title{
    font-weight: bold;
}

</style>
